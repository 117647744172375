







import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import CallToAction from '../../components/6-other/call-to-action.vue';

@Component({
  components: { CallToAction },
})
export default class Solutions extends Vue {}
